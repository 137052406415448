<template>
  <!-- <v-form ref="form_title" v-model="valid"> -->
  <div>

    <v-row class="mt-0">
      <v-col cols="12" class="pb-0">
        <p class="primary--text">{{ $t('CREATIVE.FIELD_TITLE') }}</p>
        <v-text-field
          v-model="title"
          @change="$emit('input', {title: $event})"
          required
          :rules="[$rules.maxlength(255)]"
          :placeholder="$t('JOB.FIELD_TITLE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>


  </div>

  <!-- </v-form> -->
</template>

<script>
export default {
  name:"FormCreativeCompactIntro",
  data: (_this) => ({
    valid: false,

    //
    title: undefined,
    type: undefined,
    params: {},
    channel: {},
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
    locationInput (n) {
      this.searchLocationItems(n)
    }
  },
  created () {
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'xtramile_occupation_id') {
          for (const occupation of  this.$i18n.t('JOB.XTRAMILE_OCCUPATIONS_ITEMS')) {
            if (values[key] === occupation.value) {
              values[key] = occupation
            }
          }
        }
        if (key === 'date' && typeof values[key] === 'string') values[key] = values[key].slice(0,10)
        this[key] = values[key]
      })
    },

    searchLocationItems (n, time = 200) {
      const then = (response) => {
        let data = []
        if (response && response.data) data = response.data.map(v => v.location)
        if (this.location) data = data.concat([this.location])
        this.locationItems = data
      }
      this.locationLoading = true
      if (this.locationSearchTimeout) clearTimeout(this.locationSearchTimeout)
      this.locationSearchTimeout = setTimeout(() => {
        if (n) {
          this.$services.api_programmatic
            .location.search({where: {city:n}, options: {limit: 10}})
            .then(then)
        }else {
          Promise.resolve().then(then)
        }
      }, +time || 0)
    },
  }
}
</script>
